<!-- 
    This template *should* be refactored to only use one set of <source> tags. 
    But ya know, time steals our day like a thief...
-->

<template>
    <div v-html="imageMarkup"></div>
</template>

<script>
export default {
    name: 'ImageVariants',
    props: ["imageMarkup"],
    methods: {
        // Sort the urls by key in reverse order 
        // so the media queries output correctly (large to small)
        sorted_urls(urls) {
            if (urls) {
                var result = Object.keys(urls).map(function(key) {
                    return [Number(key), urls[key]];
                });
                return result.slice().reverse();
            }
        }
    }
}
</script>
<template>
    <div class="w-full">
        <p class="text-black leading-snug text-lg font-bold mb-2">
            Enter your email below to view this resource
        </p>
        <!-- <p class="text-sm text-gray-muted mb-4 leading-snug">Your information will be used to track which of our resources are most downloaded/viewed.</p> -->
        <form @submit.prevent="handleSubmit" id="gatedForm" method="POST" accept-charset="UTF-8" class="menu__search relative w-full">
            <div class="search__wrapper relative">
                <input class="search__input invert form-input-outline focus:outline-none w-full" v-model="email" type="email" name="email" placeholder="email@example.com">
                <span class="bottom"></span>
                <span class="right"></span>
                <span class="top"></span>
                <span class="left"></span>
            </div>
            <p v-if="hasError" class="text-sm text-red-primary mt-1 mb-0">Please enter a valid email</p>
            <button :disabled="!isEmailValid" class="w-40 mt-2 btn text-sm py-2 px-4"
            :class="['input-group', isEmailValid()]">
                <span v-if="!isProcessing && !isSuccess">Submit</span>
                <span class="spinner" v-if="isProcessing"></span>
                <span v-if="isSuccess">Redirecting...</span>
            </button>
            <div class="mt-1">
                <p class="text-sm text-gray-muted" v-if="isSuccess">Click <a class="text-red-primary" :href="this.redirectUrl" >here</a> if you are not automatically redirected in <span class="text-black">{{ countDown }} seconds.</span></p>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs';
import { setTimeout } from 'timers';
export default {
    name: 'GatedForm',
    props: ['redirectUrl', 'rowLayout'],
    data() {
        return {
            countDown: 3,
            hasError: false,
            isSuccess: false,
            isProcessing: false,
            email: '',
            source: '65027',
            token: window.Craft.csrfTokenValue,
            action: '/actions/list-builder/signup/store',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    methods: {
        countDownTimer() {
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },
        handleSubmit() {
            this.isProcessing = true;

            // Build the data object to be sent
            let data = {
                'email': this.email,
                'source': this.source,
                'CRAFT_CSRF_TOKEN': this.token
            }

            if (this.isEmailValid()) {
                this.submitForm(data);   
            } else {
                this.hasError = true;
                this.isProcessing = false;
            }

        },
        isEmailValid() {
            return (this.email == "") ? "" : (this.reg.test(this.email)) ? 'has-success' : 'has-error';
        },
        submitForm(data) {
            
            // Send a POST request
            axios({
                method: 'POST',
                url: this.action,
                data: qs.stringify(data),
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest'
                },
            })
            .then(response => {
                this.hasError = false;
                this.isSuccess = true;
                this.isProcessing = false;
                this.countDownTimer();
                this.redirectToUrl();
                // console.log(response);
            })
            .catch(error => {
                this.hasError = true;
                this.isSuccess = false;
                this.isProcessing = false;
                // console.log(error)
            });
        },
        redirectToUrl() {
            setTimeout(() => {
                window.location.href = this.redirectUrl;
            }, 3000);
        }
    }
}
</script>

<style lang="scss" scoped>

    .btn {
        @apply bg-red-primary;
        &:hover {
            @apply bg-red-dark;
        }
        &.has-error {
            @apply font-bold bg-gray-muted text-white border border-gray-muted border-solid;
             &:hover {
                border-radius: 0px;
                @apply bg-transparent text-gray-muted;
            }
        }
    }

    .spinner {
        display: block;
        border: 3px solid rgba(255, 255, 255, 0.41);
        border-top-color: #ffffff;
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        margin: 0 auto;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }

</style>
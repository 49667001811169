<template>
    
    <transition-group name="entries" tag="div" class="resource__list flex flex-wrap -mx-1 md:-mx-2">

        <!-- Error message that shows when something goes wrong -->
        <div v-if="this.hasError" key="errorMessage" class="w-full text-center text-gray-muted">
            <p class='text-3xl mb-0 font-bold'>There are no articles for this criteria</p>
            <p class='mb-3 text-xl'>Please remove some filters for better results.</p>
            <button @click="clearFilters" class='btn small uppercase text-sm py-2 focus:outline-none bg-red-primary hover:bg-red-dark rounded-full'>Clear Filters</button>
        </div>

        <!-- Column -->
        <div v-else class="entries px-2 w-full sm:w-1/2 lg:w-1/4" 
            v-for="entry in articles"
            :key="entry.id"
        >

            <!-- Article -->
            <article class="relative shadow-lg bg-white h-full pb-16">

                <a v-if="entry.image.jpegSrcset" class="news__image relative block overflow-hidden" :href="entry.url">
                    <picture>
                        <source 
                            :data-srcset="entry.image.srcsetWebP"
                            data-sizes="auto"
                            type="image/webp"
                        />
                        <img 
                            class="block h-full w-full object-cover w-full blur-up lazyload"
                            :src="entry.image.srcset"
                            :data-srcset="entry.image.jpegSrcset"
                            data-sizes="auto"
                            :alt="entry.image.altText" />
                    </picture>
                </a>

                <header class="flex items-center justify-between leading-tightest p-4">
                    <p class="font-bold">
                        <a class="no-underline hover:underline text-black" :href="entry.url">
                            {{ entry.title }}
                        </a>
                    </p>
                </header>

                <footer class="flex items-center justify-between leading-none p-4 w-full absolute bottom-0">
                    <p class="text-xs font-bold mb-0">
                         <template v-if="entry.category">
                            <span v-for="cat in entry.category" :key="cat" class="text-red-primary uppercase">
                                <span class="text-gray-muted uppercase">{{ cat }} | </span>
                            </span>
                        </template> 
                        <!-- <span class="text-gray-muted uppercase">{{ entry.category }} | </span> -->
                        <span class="text-red-primary">{{ entry.postDate }}</span>
                    </p>
                    <button v-if="entry.shortUrl" class="share-icon-container float-right" 
                        :content="`Click to copy share URL`" 
                        v-tippy="{
                            arrow : true, 
                            placement: 'top-end',
                            arrowType : 'sharp', 
                            animation : 'shift-toward', 
                            interactive : true}"
                        v-clipboard:copy="entry.shortUrl"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError">
                        <!-- Share SVG -->
                        <slot name="shareIcon"></slot>
                    </button>
                </footer>

            </article>
            <!-- END Article -->

        </div>
        <!-- END Column -->
    </transition-group>

</template>

<script>
export default {
    name: 'NewsCards',
    props: ['articles', 'hasError'],
    data() {
        return {
            windowWidth: 0,
            windowHeight: 0,
        }   
    },
    mounted() {
        // this.$nextTick(function() {
        //     window.addEventListener('resize', this.getWindowWidth);
        //     window.addEventListener('resize', this.getWindowHeight);

        //     //Init
        //     this.getWindowWidth()
        //     this.getWindowHeight()
        // })

        // if( /** Mobile **/) {
        //     document.querySelectorAll('[data-tippy]').forEach(function(el){ 
        //         el._tippy && el._tippy.destroy();
        //     }
        // }
    },
    methods: {
        // Emits to root and clears all active filters
        clearFilters() {
            this.$root.$emit('removeCriteria');
        },
        getWindowWidth(event) {
            this.windowWidth = document.documentElement.clientWidth;
        },
        getWindowHeight(event) {
            this.windowHeight = document.documentElement.clientHeight;
        },
         onCopy: function (e) {
            this.$noty.success("Link successfully copied");
        },
        onError: function (e) {
            this.$noty.error("Unable to copy link");
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getWindowWidth);
        window.removeEventListener('resize', this.getWindowHeight);
    }
}
</script>

<style lang="scss">
    @import 'node_modules/vuejs-noty/dist/vuejs-noty';
</style>

<style scoped lang="scss">
    .news__image {
        height: 185px;
    }
    article {
        transition: 0.6s;
        transform-style: preserve-3d;
        border-top: 0;
    }

    .all__link {
        &:hover {
            cursor: pointer;
        }
    }

    .resource__list {
        @apply relative flex flex-wrap -mx-3;
        backface-visibility: hidden;
    }

    .entries {
        perspective: 1000px;
        transform-style: preserve-3d;
        @apply mb-4;
        position: relative;
        backface-visibility: hidden;
        transform-origin: 10% 50%;
        z-index: 1;
        
        // Moving
        &-move { transition: all 600ms ease-in-out 50ms }

        // Appearing
        &-enter-active { transition: all 300ms ease-out }

        // Dissapearing
        &-leave-active {
            transition: all 200ms ease-in;
            position: absolute;
            z-index: 0;
        }

        // Appear at / dissapear to
        &-enter,
        &-leave-to { opacity: 0 }
        &-enter { transform: scale(0.9) }
    }
</style>
<template>
    <div id="project__filters">
        <!-- Outputting mobile dropdown filter navigation -->
        <template v-if="page == 'projects' && windowWidth <= 900">
            <mobile-filter-bar :menuItems="menus" :filters="filters" :activeFilters="activeFilters"/>
            <!-- Outputting breadcrumbs of active filters -->
            <menu v-if="breadcrumbs && breadcrumbs.length > 0" class="nav list-none pb-4 flex-grow">
                <template v-for="(crumb, item) in breadcrumbs">
                    <li v-show="crumb.active" class="filters__item breadcrumb inline-block capitalize" 
                    @click="updateCriteria(crumb.group, crumb, crumb.active, $event)"
                    :key="item">
                        {{ crumb.title }}
                    </li>
                </template>
            </menu>
            <!-- Outputting clear all link -->
            <menu class="nav__controls nav__label--breadcrumbs" :class="{'nav__label--filter': breadcrumbs && breadcrumbs.length > 0}">
                <li class="nav__label nav__label--clear flex-none breadcrumb" @click="removeCriteria($event)">Clear all filters</li>
            </menu>
        </template>
        <template v-else>
            <nav class="nav">
                <!-- Outputting category groups -->
                <menu class="nav__controls">
                    <svg class="w-4 h-4 mr-4 inline-block self-center" fill="#3d5358" viewBox="0 0 24 24" id="filter" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 0l9 15.094v5.906l4 3v-8.906l9-15.094h-22zm18.479 2l-2.981 5h-8.996l-2.981-5h14.958z"></path>
                    </svg>
                    <li v-for="(active, menu) in menus" :key="menu" class="nav__label capitalize"
                        :class="{
                            'nav__label--active' : active,
                            'nav__label--filter': activeFilters[menu]
                        }" @click="setMenu(menu, active)">
                        {{ menu }}
                    </li>
                </menu>
                <!-- Outputting breadcrumbs of active filters -->
                <menu v-if="breadcrumbs && breadcrumbs.length > 0" class="mb-0 list-none p-0 flex-grow text-right">
                    <template v-for="(crumb, item) in breadcrumbs">
                        <li v-if="crumb.active" class="filters__item breadcrumb inline-block capitalize" 
                        @click="updateCriteria(crumb.group, crumb, crumb.active, $event)"
                        :key="item">
                            {{ crumb.title }}
                        </li>
                    </template>
                </menu>
                <!-- Outputting clear all link -->
                <menu class="nav__controls nav__label--breadcrumbs" :class="{'nav__label--filter': breadcrumbs && breadcrumbs.length > 0}">
                    <li class="nav__label nav__label--clear flex-none breadcrumb" @click="removeCriteria($event)">Clear all</li>
                </menu>
                
            </nav>
            <!-- Outputting filters for each category group  -->
            <transition-group name="dropdown" tag="section" class="dropdown" :style="dropdown">
                <menu v-for="(options, filter) in filters" class="filters mt-0"
                v-show="menus[filter]" ref="menu" :key="filter + 0">
                    <li v-for="option in options" :key="option.id + 0" class="filters__item"
                        :class="{ 'filters__item--active': option.active }"
                        @click="updateCriteria(filter, option, option.active, $event)">
                        {{ option.title }}
                    </li>
                </menu>
            </transition-group>
        </template>
    </div>
</template>

<script>
import MobileFilterBar from './MobileFilterBar'
export default {
    name: 'FilterBar',
    props: ['page', 'filters', 'activeFilters', 'menu', 'breadcrumbs'],
    components: {
        MobileFilterBar
    },
    data() {
        return {
            dropdown: { height: 0 },
            menus: this.menu,
            windowWidth: 0
        }
    },
    mounted() {
        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowWidth);
            this.getWindowWidth()
        })
        // Handles mobile user navigation
        this.$root.$on('set_menu', (menu, active, $event) => {
            this.setMenu(menu, active)
        })
        // Handles mobile user navigation
        this.$root.$on('update_mobile_criteria', (filter, except, active, $event) => {
            this.updateCriteria(filter, except, active, $event)
        })
    },
    computed: {
        // Keeps track of what menus are open, which dictates the dropdown functionality
        activeMenus() {
            return Object.keys(this.menus).reduce(($$, set, i) => (this.menus[set]) ? i : $$, -1)
        }
    },
    watch: {
        // Dropdown open/close functionality
        activeMenus(index, from) {
            if (index === from) return;

            this.$nextTick(() => {
                if (!this.$refs.menu || !this.$refs.menu[index]) {
                    this.dropdown.height = 0
                } else {
                    this.dropdown.height = `${this.$refs.menu[index].clientHeight + 16}px`
                }
            })
        }
    },
    methods: {
        // Calculate window width
        getWindowWidth(event) {
            this.windowWidth = window.innerWidth;
        },

        // Emits to root and fetches all active filters and updates projects
        updateCriteria(filter, except, active, $event) {
            this.$root.$emit('updateCriteria', filter, except, active, $event)
        },

        // Emits to root and removes all active filters and updates projects
        removeCriteria($event) {
            this.$root.$emit('removeCriteria', $event)
        },
        
        // Toggle the group in the menu as active once clicked
        setMenu(menu, active) {
            Object.keys(this.menus).forEach(tab => {
                this.menus[tab] = !active && tab === menu
            })
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getWindowWidth);
    }
}
</script>

<style scoped lang="scss">

    .nav {
        @apply flex border-b border-gray-lightBlue px-2;
        &__controls {
            @apply flex flex-row flex-wrap list-none p-0;
        }
        &__label {
            @apply cursor-pointer mr-6 text-lg text-gray-blue;

            &::after {
                content: '\00d7';
                display: inline-block;
                color: transparent;
                width: 0.5rem;
                font-weight: 400;
                transform: scale(0);
                transition: transform 150ms ease-in-out;
            }

            &--clear {
                color: #f68185;
                opacity: 0;
                transform: translate3d(-25%, 0, 0);
                pointer-events: none;
                transition: all 275ms ease-in-out;
            }

            &--filter ~ &--clear, &--filter &--clear {
                opacity: 1;
                transform: translate3d(0, 0, 0);
                pointer-events: auto;
            }

            &--filter::after,
            &--active::after { transform: scale(1) }

            &--filter {
                &::after {
                    content: '\2022';
                    color: #f68185;
                }
            }

            &--active::after {
                content: '\00d7';
                color: #f68185;
            }

             &--breadcrumbs {
                &::after {
                    content: "";
                }
            }
        }
        .breadcrumb {
            &::after {
                content: "\00d7";
            }   
        }
    }
    .filters {
        @apply list-none flex flex-wrap px-1;

        &__item {
            @apply py-1 px-2 mt-2 mr-2 bg-white rounded-lg border border-gray-lightBlue text-sm cursor-pointer leading-snug;
            transition: all 275ms;
            &.breadcrumb {
                @apply mt-0;
            }
            &:hover { @apply border-gray-light }

            &--active {
                @apply text-white rounded-lg bg-red-primary;
            }
        }
    }
    .dropdown {
        position: relative;
        height: 0;
        @apply mb-6 overflow-hidden;
        transition: height 350ms;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1rem;
        }

        &-enter,
        &-leave-to { opacity: 0 }

        &-leave,
        &-enter-to { opacity: 1 }

        &-enter-active,
        &-leave-active {
            position: absolute;
            width: 100%;
            transition: opacity 200ms ease-in-out;
        }

        &-enter-active { transition-delay: 100ms }
    }
</style>

<template>
    <div class="h-full flex flex-wrap flex-row mx-0 sm:-mx-6 services__snippet">
        <div v-if="services" class="services__container relative mt-0 w-full md:w-6/12 overflow-hidden md:mb-0 mb-6 sm:px-6 md:mb-0 hidden md:block">
            <transition name="fade" v-for="service in services" :key="service.slug">
                <div class="banner__image w-full h-full md:absolute mt-0" 
                    :class="{ 
                        'md:w-3/4 md:right-0': !isMobile,
                        'pl-6': isMobile
                    }"
                    v-show="service.id == activeService">
                    <div class="services__title-rotate flex flex-row flex-no-wrap" v-if="!isMobile">
                        <h1>{{ service.title }} {{ service.title }} {{ service.title }} {{ service.title }}</h1>
                    </div>
                    <div v-html="service.imageMarkup" class="absolute inset-0"></div>
                </div>
            </transition>
        </div>
        <div class="w-full md:w-6/12 sm:px-6 self-center">
            <div class="flex flex-col -mx-2 md:pl-3">
                <div class="w-full px-2 md:mt-0" v-if="copy">
                    <h1 class="heading-intro red" v-if="copy.preheading"><span>{{ copy.preheading }}</span></h1>
                    <h2 class="mt-0 mb-3 lg:whitespace-no-wrap leading-tightest" 
                        v-if="copy.heading"
                        :class="{
                            'text-3xl': isMobile
                        }">
                        {{ copy.heading }}
                    </h2>
                    <p class="text-large text-gray-muted" v-if="copy.body">{{ copy.body }}</p>
                </div>
                <div class="w-full mt-6 px-2">
                    <div class="flex flex-wrap -mx-2 -my-2">
                        <div class="p-2 w-full" 
                            v-for="service in services" 
                            :key="service.slug">
                            <a @mouseover="isMobile ? null : activeService = service.id" class="flex justify-center w-full text-center btn outline gray h-full services__buttons" :href="service.url">
                                <img :style="{ width: '2rem' }" :src="service.iconUrl" /> 
                                <span class="service__button-title self-center ml-2">{{ service.title }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesToggle',
    props: ['services', 'copy'],
    data() {
        return {
            activeService: this.setActiveService(),
            isMobile: window.matchMedia('(max-width: 900px)').matches
        }
    },
    mounted() {
        window.addEventListener('resize', this.mq);
        window.addEventListener('load', this.mq);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.mq);
        window.removeEventListener('load', this.mq);
    },
    methods: {
        setActiveService() {
            return this.services[0].id;
        },
        mq() {
            this.isMobile = window.matchMedia('(max-width: 900px)').matches;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .services__snippet {
        .services__container {
            box-sizing: border-box;
            // @media(min-width: 1280px) {
                @screen md {
                    padding-bottom: 35rem;
                }
            // }
        }
        .banner__image {
            padding-bottom: 15rem;
            @screen sm {
                padding-bottom: 35rem;
            }
        }
        .services__title-rotate {
            margin-left: -130%;
            margin-top: -18%;
            transform: rotate(-90deg);
            h1 {
                animation: moveText 90s linear infinite;
                color: #F1F4F9;
                font-size: 8rem;
                @apply whitespace-no-wrap;
            }
        }
        .service__button-title {
            font-size: 1.2rem;
        }
    }
    @keyframes moveText {
        100% {
            transform: translateX(-100%);
        }
    }
</style>
<template>
    <on-click-outside :do="handleClickOutside">
        <div class="relative w-64">
            <button @click="open = !open" type="button" class="button__dropdown bg-white text-left w-full block font-bold px-4 py-2 focus:outline-none flex">
                <span class="self-center flex-grow">Filter</span>
                <span class="button__arrow-down self-center">
                    <font-awesome-icon icon="chevron-down" class="text-gray-muted" />
                </span>
            </button>
            <transition name="jump">
                <ul v-if="open" class="button__list absolute inset-x-0 mt-px text-grey-darker leading-normal bg-white border z-10 shadow-xl">
                    <div class="overflow-hidden w-full h-full">
                        <div class="relative overflow-scroll scrolling-touch">
                            <li v-for="(active, menu) in menuItems" :key="menu" class="trans font-bold capitalize">
                            
                                <!-- Output the main dropdown contents -->
                                <div class="flex justify-between text-sm" @click="triggerMenu(menu, active, $event)">
                                    <div class="flex flex-grow">
                                        <span>{{ menu }} </span>
                                        <span v-if="activeFilters[menu]" class="active-filter ml-1 w-4 h-4 text-white bg-red-primary rounded-full flex justify-center">1</span>
                                    </div>
                                    <font-awesome-icon icon="chevron-right" 
                                        class="next-arrow text-gray-muted self-center" 
                                    />
                                </div>
                                
                                <!-- Output the individual categories -->
                                <transition name="slide-left">
                                    <template v-for="(options, filter) in filters">
                                        <ul v-if="menu == filter" v-show="menuItems[filter]" :key="filter + 0" 
                                            class="absolute inset-0 bg-white m-0"
                                            :class="{'extra-space': showGradient}">
                                            <li class="text-sm text-red-primary" @click="triggerMenu(menu, active, $event)">
                                                <font-awesome-icon icon="chevron-left" class="next-arrow" /> Back
                                            </li>
                                            <li v-for="option in options" 
                                                :key="option.id + 0" 
                                                @click="updateCriteria(filter, option, option.active, $event)"
                                                class="font-normal text-sm"
                                                :class="{'filters__item--active': option.active}">
                                                {{ option.title }}
                                            </li>
                                        </ul>
                                    </template>
                                </transition>
                            </li>
                        </div>
                        <span v-if="showGradient" class="gradient"></span>
                    </div>
                </ul>
            </transition>
        </div>
    </on-click-outside>
</template>

<script>
import OnClickOutside from './OnClickOutside'
export default {
    name: 'MobileFilterBar',
    props: ['menuItems', 'filters', 'activeFilters'],
    components: {
        OnClickOutside
    },
    data() {
        return {
            open: false,
            showGradient: false
        }
    },
    methods: {
        handleClickOutside() {
            if (this.open) {
                this.open = false;
            }
        },
        triggerMenu(menu, active, $event) {
            this.$root.$emit('set_menu', menu, active, $event);
            if (Object.keys(this.filters[menu]).length > 4) {
                this.showGradient = !this.showGradient;
            }
        },
        updateCriteria(filter, except, active, $event) {
            this.$root.$emit('update_mobile_criteria', filter, except, active, $event);
        }
    }
}
</script>

<style lang="scss">
    @import 'node_modules/vue-transition-css/dist/css/vue-transition';
</style>

<style scoped lang="scss">
    .filters__item--active {
        @apply bg-red-primary text-white;
    }
    .button__dropdown, .button__list {
        border: 1px solid #e2e2e2;
    }
    .button__list {
        li {
            border-bottom: 1px solid #e2e2e2;
            @apply px-4 py-2;
            &:last-child {
                border-bottom: 0;
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    .button__arrow-down {
        display: block;
        height: 20px;
        width: 20px;
    }
    .next-arrow {
        width: 0.5rem;
    }
    .active-filter {
        font-size: 0.74rem;
    }
    .extra-space {
        li {
            &:last-child {
                padding-bottom: 20%;
            }
        }
    }
    .gradient {
        position: absolute;
        width: 100%;
        height: 40%;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        background-image: linear-gradient(180deg,rgba(255, 255, 255, 0),#fff)
    }
</style>
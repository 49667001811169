import Vue from 'vue';
import Components from './components';
import Modernizr from './modernizr';
import VueReadProgress from "vue-read-progress";
import './scss/app.scss'
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit.js';
import 'lazysizes/plugins/respimg/ls.respimg.js';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import picturefill from 'picturefill';
import VueTippy, { VueComponent } from 'vue-tippy';
import VueClipboard from 'vue-clipboard2'
import VueNoty from 'vuejs-noty'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronRight, faChevronLeft, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Init and add fontawesome icons
library.add(faChevronDown, faChevronRight, faChevronLeft, faFilter)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Vue specific stuff
Vue.config.productionTip = false

// Register components with options
Vue.use(VueTippy,{
  directive : 'tippy', // => v-tippy
  flipDuration: 0,
  popperOptions: {
      modifiers: {
          preventOverflow: {
              enabled: false
          }
      }
  }
})
Vue.use(VueClipboard);
Vue.use(VueNoty, {
  theme: 'relax',
  timeout: 3000
});

const app = () => {
  new Vue({
    el: '#app',
    delimiters: ['${', '}'],
    data() {
      return {
        navIsActive: false,
        showNavbar: true,
        lastScrollPosition: 0,
        currentScrollPosition: 0,
        windowWidth: window.innerWidth
      }
    },
    components: {
      VueReadProgress
    },
    computed: {
     isMobile() {
      return this.windowWidth <= 768
     }
    },
    mounted() {

      window.addEventListener('scroll', this.onScroll);
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth()

      picturefill();
      
      // Init lazySizes once everything is mounted
      lazySizes.init();

      // Adds extra bottom padding to sections that are above a 'bg-*' class
      var pDoc = document.getElementsByClassName("js-editorial");
      if (pDoc) {
        for (let index = 0; index < pDoc.length; index++) {
          const prevDiv = pDoc[index].previousElementSibling;
          if (!prevDiv.classList.contains('js-editorial')) {
            prevDiv.classList.add("pb-16");
          }
        }
      }

      // "Accordion" logic
      var myRadios = document.getElementsByName('tabs2');
      var setCheck;
      var x = 0;
      for(x = 0; x < myRadios.length; x++){
          if (x == 0) {
            myRadios[x].checked = true;
          }
          myRadios[x].onclick = function(){
              if (setCheck != this){
                  setCheck = this;
              } else {
                  this.checked = false;
                  setCheck = null;
          }
        };
      }

      // Having some fun
      if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
          var t = ["\n %c Made with ♥ by Rockit Science Agency %c %c %c https://www.rockitscienceagency.com/ %c %c🚀 \n\n", "color: #7ec156; background: #202c31; padding:5px 0;", "background: #494949; padding:5px 0;", "background: #494949; padding:5px 0;", "color: #fff; background: #1c1c1c; padding:5px 0;", "padding:5px 0;", "color: #b0976d; padding:5px 0;"];
          window.console.log.apply(console, t);
      } else {
          window.console && window.console.log("Made with love ♥ Rockit Science Agency - https://www.rockitscienceagency.com/ 🚀");
      }
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll);
      window.removeEventListener('resize', this.getWindowWidth);
    },
    methods: {
      onScroll () {
        // Get the current scroll position
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        this.currentScrollPosition = window.scrollY

        // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
        if (currentScrollPosition < 0) {
          return
        }

        // Stop executing this function if the difference between
        // current scroll position and last scroll position is less than some offset
        if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
          return
        }
        this.showNavbar = currentScrollPosition < this.lastScrollPosition
        this.lastScrollPosition = currentScrollPosition
      },
      // Calculate window width
      getWindowWidth() {
        this.windowWidth = window.innerWidth;
      },
    }
  })
}

// Loads JS asynchronously
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', app); // Document still loading so DomContentLoaded can still fire
} else {
  app()
}
<template>
    <div class="w-full">
        <!-- Filter bar -->
        <filter-bar 
            :filters="categories" 
            :menu="menuItems" 
            :is-processing="isProcessing" 
            :active-filters="activeFilters">
        </filter-bar>

        <!-- Listing of resources -->
        <resource-cards :has-error="hasError" :resources="resources">
            <!-- Passing icons to child component from parent -->
            <slot name="arrowIcon" slot="arrowIcon"></slot>
            <slot name="lockIcon" slot="lockIcon"></slot>
            <slot name="shareIcon" slot="shareIcon"></slot>
            <slot name="backIcon" slot="backIcon"></slot>
        </resource-cards>

        <!-- Loading icon -->
        <div v-show="showSpinner" class="w-full flex justify-center py-10" key="processing">
            <div class="dots">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

        <!-- Load more resources button -->
        <div class="w-full text-center" v-show="nextPage > 1 && !isProcessing">
            <button @click="loadMoreEntries()" class='btn small uppercase text-sm py-2 focus:outline-none bg-red-primary hover:bg-red-dark rounded-full'>Load More</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import FilterBar from './FilterBar'
import ResourceCards from './ResourceCards'
export default {
    name: 'ResourcesListing',
    data() {
        return {
            resources: [],
            categories: [],
            selectedCategory: [],
            nextPage: 1,
            showSpinner: 1,
            isProcessing: false,
            hasError: false,
            menuItems: {
                types: false
            }
        }
    },
    components: {
        ResourceCards,
        FilterBar
    },
    mounted() {
        this.getResources()
        this.getCategories()

        // Set active filters and fetch the projects
        this.$root.$on('updateCriteria', (filter, except, active, $event) => {
            this.updateFilters(filter, except, active);
        })

        // Clears all filters
        this.$root.$on('removeCriteria', ($event) => {
            this.clearFilters();
        })
    },
    computed: {
        // Get the filters that are active and pass to child component for state evalutaion
        activeFilters() {
            return {
                type: this.selectedCategory.active ? this.selectedCategory.active : false,
            }
        }
    },
    methods: {
        getResources(nextPage) {
            this.isProcessing = true
            axios({
                url: `/api/resources.json?${qs.stringify(
                    { 
                        // Pass in the parameters based on what filters are active
                        type: this.selectedCategory.active ? this.selectedCategory : null,
                        pg: nextPage > 0 ? nextPage : null
                    }, 
                    {arrayFormat: 'brackets'}
                )}`,
                method: 'get',
            }).then((response) => {
                console.log(response.status);
                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        
                        // Reset error status
                        this.hasError = false;
                        
                        // Add or fetch new data if there are multiple pages
                        if (nextPage > 1) {
                            this.resources = this.resources.concat(response.data.data);   
                        } else {
                            this.resources = response.data.data;
                            if (this.resources.length == 0) {
                                this.hasError = true;
                            }
                        }

                        // Increase the nextPage count
                        this.nextPage = this.nextPage + 1;

                        // Reset nextPage status if there are no more pages
                        if (response.data.meta.pagination.total_pages == response.data.meta.pagination.current_page) {
                            this.nextPage = 1;
                        }
                        
                    } else {
                        this.nextPage = 1;
                        this.hasError = true
                        console.log("Error: response failed");
                    }

                    this.showSpinner = 0;
                }
                this.isProcessing = false
            }).catch((error) => {
                this.hasError = true
            })
        },
        getCategories() {
            this.isProcessing = true
            axios({
                url: `/api/resource-types.json`,
                method: 'get',
            }).then((response) => {
                if (response.status == 200) {
                    this.categories = response.data.categories
                }
                this.isProcessing = false
            }).catch((error) => {
                this.hasError = true
            })
        },
        loadMoreEntries() {
            this.showSpinner = true;
            this.getResources(this.nextPage);
        },
        clearFilters() {
            // Remove active state from tags
            Object.keys(this.categories).forEach(filter => {
                this.categories[filter].forEach(option => {
                    option.active = false;
                });
            });

            // Reset the nextPage to it's default value
            this.nextPage = 1;

            // Fetch all resources
            this.getResources()
        },
        updateFilters(filter, except, active) {
            this.categories[filter].forEach(option => {
                
                // Toggle the 'tag' active state on/off
                option.active = except.slug === option.slug && !active

                if (option.active) {
                    this.selectedCategory = option;
                }
            })

            // Fetch the projects with the filters
            this.getResources()
        },
    }
}
</script>

<style scoped lang="scss">

    .resource__specs {
        border-top: 1px solid #e3e3e3;
        @apply pt-4;
    }

    .svg-red {
        svg path {
            fill: #DD1F26;
        }
    }

    .svg-gray {
        svg path {
            fill: #8b8b8b;
        }
    }

    .dots {
        width: 4em;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }

    .dots div {
        width: 1em;
        height: 1em;
        border-radius: 50%;
        @apply bg-gray-light;
        animation: fade 0.8s ease-in-out alternate infinite;
    }

    .dots div:nth-of-type(1) {
        animation-delay: -0.4s;
    }

    .dots div:nth-of-type(2) {
        animation-delay: -0.2s;
    }

    @keyframes fade {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
</style>

<template>
    <form @submit.prevent="handleSubmit" id="gatedForm" method="POST" accept-charset="UTF-8" class="menu__search relative w-full">
        <p class="text-white mb-5">Sign up to get the latest news and updates from Labarre Asociates.</p>
        <div class="flex flex-wrap md:flex-no-wrap">
            <input class="invert form-input-outline focus:outline-none w-full mr-3 mb-3 md:mb-0" v-model="email" type="email" name="email" placeholder="email@example.com">
            <button :disabled="!isEmailValid || isSuccess" class="btn red focus:outline-none" 
                :class="{
                    'input-group': isEmailValid(),
                    'green disabled': isSuccess
                }">
                <span v-if="!isProcessing && !isSuccess">Subscribe</span>
                <span class="spinner" v-if="isProcessing"></span>
                <span v-if="isSuccess">Success</span>
            </button>
        </div>
        <p v-if="hasError" class="text-sm text-red-primary mt-1 mb-0">Please enter a valid email</p>
        <p v-if="isSuccess" class="mt-1 mb-0 text-green-500">You have successfully subscribed to our newsletter!</p>
    </form>
</template>

<script>
import axios from 'axios'
import qs from 'qs';
export default {
    name: 'FooterForm',
    data() {
        return {
            hasError: false,
            isSuccess: false,
            isProcessing: false,
            email: '',
            source: '1A8B6',
            token: window.Craft.csrfTokenValue,
            action: '/actions/list-builder/signup/store',
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    methods: {
        handleSubmit() {
            this.isProcessing = true;

            // Build the data object to be sent
            let data = {
                'email': this.email,
                'source': this.source,
                'CRAFT_CSRF_TOKEN': this.token
            }

            if (this.isEmailValid()) {
                this.submitForm(data);   
            } else {
                this.hasError = true;
                this.isProcessing = false;
            }

        },
        isEmailValid() {
            return (this.email == "") ? "" : (this.reg.test(this.email)) ? 'has-success' : 'has-error';
        },
        submitForm(data) {
            
            // Send a POST request
            axios({
                method: 'POST',
                url: this.action,
                data: qs.stringify(data),
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest'
                },
            })
            .then(response => {
                this.hasError = false;
                this.isSuccess = true;
                this.isProcessing = false;
                
                // console.log(response);
            })
            .catch(error => {
                this.hasError = true;
                this.isSuccess = false;
                this.isProcessing = false;
                // console.log(error)
            });
        }
    }
}
</script>

<style lang="scss" scoped>

    .btn {
        @apply bg-red-primary;
        &:hover {
            @apply bg-red-dark;
        }
        &.has-error {
            @apply font-bold bg-gray-muted text-white border border-gray-muted border-solid;
             &:hover {
                border-radius: 0px;
                @apply bg-transparent text-gray-muted;
            }
        }
    }

    .spinner {
        display: block;
        border: 3px solid rgba(255, 255, 255, 0.41);
        border-top-color: #ffffff;
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        margin: 0 auto;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }

</style>
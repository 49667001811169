<template>
    <div class="w-full" v-cloak>
        
        <!-- Filter bar -->
        <filter-bar page="projects" :breadcrumbs="globalActiveFilters" :menu="menuItems" :filters="categories" :is-processing="isProcessing" :active-filters="activeFilters"></filter-bar>

        <!-- Main content of page -->
        <transition-group name="entries" tag="ul" class="project__list">

            <!-- Error message that shows when something goes wrong -->
            <li v-if="hasError" key="errorMessage" class="w-full text-center text-gray-muted">
                <p class='text-3xl mb-0 font-bold'>There are no projects for this criteria</p>
                <p class='mb-3 text-xl'>Please remove some filters for better results.</p>
                <button @click="clearFilters" class='btn small uppercase text-sm py-2 focus:outline-none bg-red-primary hover:bg-red-dark rounded-full'>Clear Filters</button>
            </li>

            <!-- Listing of entries -->
            <li v-else class="entries" v-for="proj in projects" :key="proj.id">
                <article>
                    <a v-if="proj.src" :href="proj.url" class="project__link block">
                        <div class="project__image overflow-hidden rounded-lg shadow-lg">
                            <picture>
                                <source 
                                    :data-srcset="proj.webpSrcset"
                                    data-sizes="auto"
                                    type="image/webp"
                                />
                                <img 
                                    class="w-full blur-up lazyload"
                                    :src="proj.src"
                                    :data-srcset="proj.jpegSrcset"
                                    data-sizes="auto"
                                    :alt="proj.altText" />
                            </picture>
                        </div>
                    </a>
                    <a v-else :href="proj.url" class="project__link block h-full">
                         <div class="project__image-placeholder relative overflow-hidden rounded-lg shadow-lg lg:h-84 bg-white">
                            <template v-if="proj.clientLogo">
                                <img :src="proj.clientLogo" :alt="proj.client[0]" class="center-logo absolute lg:relative" :class="
                                { 
                                    'pt-3 px-27 mx-auto': proj.isTall,
                                    'py-10 px-16 mx-auto': !proj.isTall
                                }">
                            </template>
                            <template v-else>
                                <picture>
                                    <source 
                                        :data-srcset="proj.placeholderWebP"
                                        data-sizes="auto"
                                        type="image/webp"
                                    />
                                    <img 
                                        class="w-full blur-up lazyload"
                                        :src="proj.placeholderSrc"
                                        :data-srcset="proj.placeholderJpg"
                                        data-sizes="auto"
                                        :alt="proj.altText" />
                                </picture>
                            </template>
                         </div>
                    </a>
                    <header class="bg-white leading-tightest shadow-lg rounded-lg z-1 relative w-11/12 mx-auto p-5 -mt-24">
                        <div class="flex flex-row flex-wrap">
                            <div class="w-full sm:w-9/12">
                                <p v-if="proj.client" class="uppercase font-bold text-red-primary text-xs mb-0">{{ proj.client[0] }}</p>
                                <a class="hover:no-underline hover:underline w-full block" :href="proj.url">
                                    <p class="font-bold text-lg text-black mb-1 font-heading-style normal-case">{{ proj.title }}</p>
                                </a>
                                <p v-if="proj.location" class="text-gray-muted text-sm font-bold mb-3">
                                    <!-- Passing in icon from twig via slot -->
                                    <slot></slot> 
                                    {{ proj.location[0] }}
                                </p>
                            </div>
                            <div class="w-full sm:w-3/12">
                                <button v-if="proj.shortUrl" v-on:click.self.prevent class="share-icon-container float-right" 
                                    :content="`Click to copy share URL`" 
                                    v-tippy="{
                                        arrow : true, 
                                        placement: 'top-end',
                                        arrowType : 'sharp', 
                                        animation : 'shift-toward', 
                                        interactive : true}"
                                    v-clipboard:copy="proj.shortUrl"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError">
                                    <slot name="shareIcon"></slot>
                                </button>
                            </div>
                        </div>
                        <div class="mt-4 mx-auto block project__specs">
                            <div class="flex flex-wrap md:justify-between">
                                <div class="mr-6" v-if="proj.industry">
                                    <p class="text-gray-muted font-bold text-sm mb-0">Industry:</p>
                                    <p class="text-sm font-bold text-black">{{ proj.industry[0] }}</p>
                                </div>
                                <div class="mr-6" v-if="proj.sizeExact">
                                    <p class="text-gray-muted font-bold text-sm mb-0">Size:</p>
                                    <p class="text-sm font-bold text-black">{{ proj.sizeExact }}sf</p>
                                </div>
                                <div v-if="proj.services">
                                    <p class="text-gray-muted font-bold text-sm mb-0">Service Team:</p>
                                    <ul class="mb-0 mt-1">
                                        <template v-if="proj.services.length >= 4">
                                            <li class="text-sm font-bold text-black">All Services</li>
                                        </template>
                                        <template v-else>
                                            <li v-for="service in proj.services" :key="service" class="text-sm font-bold text-black">
                                                {{ service }}
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                </article>
            </li>
        </transition-group>

        <!-- Loading icon -->
        <div v-show="showSpinner" class="w-full flex justify-center py-10" key="processing">
            <div class="dots">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

        <!-- Load more entries button -->
        <div class="w-full text-center" v-show="nextPage > 1 && !isProcessing">
            <button @click="loadMoreEntries()" class='btn small uppercase text-sm py-2 focus:outline-none bg-red-primary hover:bg-red-dark rounded-full'>Load More</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import FilterBar from './FilterBar'
export default {
    name: 'ProjectsListing',
    data() {
        return {
            projects: [],
            categories: [],
            selectedClient: [],
            selectedLocation: [],
            selectedIndustry: [],
            selectedService: [],
            selectedSize: [],
            globalActiveFilters: [],
            queryParam: '',
            nextPage: 1,
            showSpinner: 1,
            isProcessing: false,
            hasError: false,
            menuItems: { 
                clients: false, 
                location: false, 
                industry: false, 
                services: false, 
                sizes: false 
            }
        }
    },
    components: {
        FilterBar
    },
    mounted() {
        this.queryParam = this.getUrlParameter('service');
        if (!this.queryParam) {
            this.getProjects()   
        }
        this.getCategories()

        // Set active filters and fetch the projects
        this.$root.$on('updateCriteria', (filter, except, active, $event) => {
            this.updateFilters(filter, except, active);
        })

        // Clears all filters
        this.$root.$on('removeCriteria', ($event) => {
            this.clearFilters();
        })
    },
    computed: {
        // Get the filters that are active and pass to child component for state evalutaion
        activeFilters() {
            return {
                clients: this.selectedClient.active ? this.selectedClient.active : false,
                location: this.selectedLocation.active ? this.selectedLocation.active : false,
                industry: this.selectedIndustry.active ? this.selectedIndustry.active : false,
                services: this.selectedService.active ? this.selectedService.active : false,
                sizes: this.selectedSize.active ? this.selectedSize.active : false
            }
        }
    },
    methods: {
        fetchResultsFromParam(categories) {
            this.categories['services'].forEach(option => {
                if (option.slug == this.queryParam) {
                    option.active = true;
                    this.selectedService = option;
                    this.globalActiveFilters = [option];
                }
            });
            this.getProjects();
        },
        getUrlParameter(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        },
        getProjects(nextPage) {
            this.isProcessing = true
            axios({
                url: `/api/projects.json?${qs.stringify(
                    { 
                        // Pass in the parameters based on what filters are active
                        client: this.selectedClient.active ? this.selectedClient : null,
                        location: this.selectedLocation.active ? this.selectedLocation : null,
                        industry: this.selectedIndustry.active ? this.selectedIndustry : null,
                        service: this.selectedService.active ? this.selectedService : null,
                        size: this.selectedSize.active ? this.selectedSize : null,
                        pg: nextPage > 0 ? nextPage : null
                    }, 
                    {arrayFormat: 'brackets'}
                )}`,
                method: 'get',
            }).then((response) => {
                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        
                        // Reset error status
                        this.hasError = false;
                        
                        // Add or fetch new data if there are multiple pages
                        if (nextPage > 1) {
                            this.projects = this.projects.concat(response.data.data);   
                        } else {
                            this.projects = response.data.data;
                        }

                        // Increase the nextPage count
                        this.nextPage = this.nextPage + 1;

                        // Reset nextPage status if there are no more pages
                        if (response.data.meta.pagination.total_pages == response.data.meta.pagination.current_page) {
                            this.nextPage = 1;
                        }
                        
                    } else {
                        this.nextPage = 1;
                        this.hasError = true
                    }

                    this.showSpinner = 0;
                }
                this.isProcessing = false
            }).catch((error) => {
                this.hasError = true
            })
        },
        getCategories() {
            this.isProcessing = true
            axios({
                url: `/api/project-filters.json`,
                method: 'get',
            }).then((response) => {
                if (response.status == 200) {
                    this.categories = response.data.categories
                    if (this.queryParam) {
                        this.fetchResultsFromParam(this.categories);
                    }
                }
                this.isProcessing = false
            }).catch((error) => {
                this.hasError = true
            })
        },
        loadMoreEntries() {
            this.showSpinner = true;
            this.getProjects(this.nextPage);
        },
        clearFilters() {
            // Remove active state from tags
            Object.keys(this.categories).forEach(filter => {
                this.categories[filter].forEach(option => {
                    option.active = false;
                });
            });

            // Reset the nextPage to it's default value
            this.nextPage = 1;

            // Remove all active global filters to reset breadcrumb
            this.globalActiveFilters = [];

            // Fetch all projects
            this.getProjects()
        },
        updateFilters(filter, except, active) {
            this.categories[filter].forEach(option => {
                
                // Toggle the 'tag' active state on/off
                option.active = except.slug === option.slug && !active
                
                // Add the appropriate filters to the global filters var
                if (option.active) {
                    this.globalActiveFilters.push(option);
                } else {
                    this.globalActiveFilters.filter((el, index) => {
                        if (el.id == option.id) {
                            this.globalActiveFilters.splice(index, 1);
                        }
                    });   
                }

                // There's most likely a better way to do this...
                if (option.group == 'clients' && option.active) {
                    this.selectedClient = option;
                }
                if (option.group == 'location' && option.active) {
                    this.selectedLocation = option;
                }
                if (option.group == 'industry' && option.active) {
                    this.selectedIndustry = option;
                }
                if (option.group == 'services' && option.active) {
                    this.selectedService = option;
                }
                if (option.group == 'sizes' && option.active) {
                    this.selectedSize = option;
                }

            })

            // Fetch the projects with the filters
            this.getProjects()
        },
        updateBreadCrumbs(crumb) {
            if (this.globalActiveFilters.length > 0) {
                this.globalActiveFilters.filter((el, index) => {
                    // if (el.group == crumb.group) {
                        this.globalActiveFilters.splice(index, 1);
                    // }
                    this.globalActiveFilters.push(crumb);   
                });   
            }
            else {
                this.globalActiveFilters.push(crumb);
            }
        },
        onCopy: function (e) {
            this.$noty.success("Link successfully copied");
        },
        onError: function (e) {
            this.$noty.error("Unable to copy link");
        }
    }
}
</script>

<style lang="scss">
    @import 'node_modules/vuejs-noty/dist/vuejs-noty';
</style>

<style scoped lang="scss">
    
    [v-cloak] {
        display: none;
    }

    .project__list {
        @apply relative flex flex-wrap -mx-3;
        backface-visibility: hidden;
    }

    .entries {
        @apply w-full mb-10 px-3;
        position: relative;
        backface-visibility: hidden;
        transform-origin: 10% 50%;
        z-index: 1;

        .project__image-placeholder {
            padding-bottom: 75%;
            img {
                left: 50%;
                transform: translate(-50%, 0);
            }
            @screen lg {
                padding-bottom: 0;
            }
            @screen lg {
                img {
                    left: auto;
                    transform: translate(0, 0);
                }
            }
        }

        @screen sm {
            @apply w-6/12;
        }

        @screen lg {
            @apply w-4/12;
        }
        
        // Moving
        &-move { transition: all 600ms ease-in-out 50ms }

        // Appearing
        &-enter-active { transition: all 300ms ease-out }

        // Dissapearing
        &-leave-active {
            transition: all 200ms ease-in;
            position: absolute;
            z-index: 0;
        }

        // Appear at / dissapear to
        &-enter,
        &-leave-to { opacity: 0 }
        &-enter { transform: scale(0.9) }
    }

    .project__link {
        img {
            transform-origin: 50% 50%;
            transition: transform .3s ease-out;
        }
        @screen lg {
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    .project__specs {
        border-top: 1px solid #e3e3e3;
        @apply pt-4;
    }

    .svg-red {
        svg path {
            fill: #DD1F26;
        }
    }

    .svg-gray {
        svg path {
            fill: #8b8b8b;
        }
    }

    .dots {
        width: 4em;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }

    .dots div {
        width: 1em;
        height: 1em;
        border-radius: 50%;
        @apply bg-gray-light;
        animation: fade 0.8s ease-in-out alternate infinite;
    }

    .dots div:nth-of-type(1) {
        animation-delay: -0.4s;
    }

    .dots div:nth-of-type(2) {
        animation-delay: -0.2s;
    }

    @keyframes fade {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
</style>

<template>
    <on-click-outside :do="handleClickOutside">
        <div class="relative w-full sm:w-64 mb-10">
            <button @click="open = !open" type="button" class="button__dropdown bg-white text-left w-full block font-bold px-4 py-2 focus:outline-none flex">
                <span :style="{ width: '40px', height: '40px' }"><img class="block w-full h-full" :src="currentPageIcon" alt=""></span>
                <span class="self-center flex-grow ml-1">{{ currentPageTitle }}</span>
                <span class="button__arrow-down self-center" :style="{ fill: '#BCBCBC' }"><slot></slot></span>
            </button>
            <transition name="fade">
                <ul v-if="open" class="button__list absolute inset-x-0 mt-px text-sm text-center text-grey-darker leading-normal bg-white border p-4">
                    <li v-for="service in services" :key="service.id">
                        <a :href="service.url" class="trans hover:text-red-primary font-bold">{{ service.title }}</a>
                    </li>
                </ul>
            </transition>
        </div>
    </on-click-outside>
</template>

<script>
import OnClickOutside from './OnClickOutside'
export default {
    name: 'ServicesDropdown',
    props: ['services'],
    components: {
        OnClickOutside
    },
    data() {
        return {
            open: false
        }
    },
    computed: {
        currentPageTitle() {
            let result = this.getCurrentPage();
            return result.title;
        },
        currentPageIcon() {
            let result = this.getCurrentPage();
            return result.iconUrl;
        }
    },
    methods: {
        handleClickOutside() {
            if (this.open) {
                this.open = false;
            }
        },
        getCurrentPage() {
            const lastSegment = window.location.pathname.split('/').filter(el => el).pop();
            const result = this.services.find( el => el.slug === lastSegment );
            return result;
        }
    }
}
</script>

<style scoped lang="scss">
    .button__dropdown, .button__list {
        border: 1px solid #BCBCBC;
    }
    .button__list {
        top: 3.5rem;
    }
    .button__arrow-down {
        display: block;
        height: 20px;
        width: 20px;
    }
</style>
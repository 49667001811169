<template>
    <div class="glide" id="glide-container">
        <div class="glide__track relative" style="overflow: visible;" id="glide-track" data-glide-el="track">
            <ul class="glide__slides mb-0">
                <li class="glide__slide h-full" v-for="image in images" :key="image.id">
                    <picture>
                        <source 
                            :data-srcset="image.srcsetWebP"
                            data-sizes="auto"
                            type="image/webp"
                        />
                        <img 
                            class="glide__image mediabox-img z-1 relative blur-up lazyload"
                            :src="image.src" 
                            :data-srcset="image.srcset"
                            data-sizes="auto" />
                    </picture>
                </li>
            </ul>
             <div class="glide__arrows absolute" data-glide-el="controls">
                <button class="float-left pl-5 focus:outline-none control__buttons" data-glide-dir="<" v-html="controlicons.left"></button>
                <button class="float-right pr-5 focus:outline-none control__buttons" data-glide-dir=">" v-html="controlicons.right"></button>
            </div>
        </div>
    </div>
</template>

<script>
import Glide, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm'
export default {
    name: 'Slider',
    props: ['images', 'controlicons'],
    mounted: function() {
        new Glide('.glide', {
            type: 'slider',
            autoplay: 0,
            animationDuration: 800,
            animationTimingFunc: 'ease',
            perView: 1,
            gap: 25,
            peek: {
                before: 0,
                after: 100
            } 
        }).mount({ Controls, Swipe })
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
    .glide__arrows {
        width: 100%;
        top: 50%;
        left: 0; 
        button {
            width: 3rem;
        }
    }
    .glide__slide {
        width: 100%;
        height: 100%;
        box-shadow: inset 0 9px 0px 0px white, inset 0 -9px 0px 0px white, inset 80px 0 60px -4px rgba(0, 0, 0, 0.24), inset -80px 0 60px -4px rgba(0, 0, 0, 0.24);
    }
    @screen md {
        .glide__image {
            z-index: -1;
        }
    }
</style>

<!-- Global component css here (for when scoped css is a bit to precise) -->
<style lang="scss">
    .control__buttons {
        svg {
            fill: #ffffff;
        }
    }
</style>
<template>
    
    <!-- Column -->
    <transition-group name="entries" tag="div" class="resource__list flex flex-wrap -mx-1 md:-mx-2">
        
        <!-- Error message that shows when something goes wrong -->
        <div v-if="this.hasError" key="errorMessage" class="w-full text-center text-gray-muted">
            <p class='text-3xl mb-0 font-bold'>There are no resources for this criteria</p>
            <p class='mb-3 text-xl'>Please remove some filters for better results.</p>
            <button @click="clearFilters" class='btn small uppercase text-sm py-2 focus:outline-none bg-red-primary hover:bg-red-dark rounded-full'>Clear Filters</button>
        </div>

        <div v-else class="entries px-1 px-2 w-full sm:w-1/2 lg:w-1/4" 
            v-for="entry in resources"
            :key="entry.id">

            <!-- Article -->
            <article class="resource__card relative shadow-lg h-full"
                :class="{ 'flip': entry.id == itemId }">

                <!-- Front of card -->
                <div class="front bg-white h-full pb-16 p-4">

                    <!-- Lock Icon -->
                    <template v-if="entry.gated">
                        <slot name="lockIcon"></slot>
                    </template>

                    <header class="flex flex-col justify-between mb-2">
                        <p class="text-xs font-bold mb-2">
                            <template v-if="entry.category">
                                <span v-for="cat in entry.category" :key="cat" class="text-red-primary uppercase">
                                    {{ cat }}
                                </span>
                            </template> 
                        </p>
                        <p class="text-lg font-bold leading-snug cursor-pointer text-black">
                            <template v-if="!entry.gated">
                                <a :href="entry.url" class="text-black">
                                    {{ entry.title }}
                                </a>
                            </template>
                            <template v-else>
                                <span @click="itemId = entry.id" class="text-black">
                                    {{ entry.title }}
                                </span>
                            </template>
                        </p>
                    </header>

                    <truncate v-if="entry.overview" clamp="" :length="125" type="html" :text="entry.overview" class="text-sm text-gray-muted"></truncate>

                    <footer class="flex items-center justify-between leading-none p-4 absolute bottom-0 right-0 left-0">
                        <template v-if="!entry.gated">
                            <a :href="entry.url" target="_self" class="focus:outline-none hover:text-black text-black all__link outline-none trans trans-slower">
                                <span class="link-text uppercase text-xs font-bold">
                                    Read More 
                                    <span class="svg-arrow-right">
                                        <!-- Arrow SVG -->
                                        <slot name="arrowIcon"></slot>
                                    </span>
                                </span>
                            </a>
                            <button v-if="entry.shortUrl" class="share-icon-container" 
                                :content="`Click to copy share URL`" 
                                v-tippy="{
                                    arrow : true,
                                    placement: 'top-end', 
                                    arrowType : 'sharp', 
                                    animation : 'shift-toward', 
                                    interactive : true}"
                                v-clipboard:copy="entry.shortUrl"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError">
                                <!-- Share SVG -->
                                <slot name="shareIcon"></slot>
                            </button>
                        </template>
                        <template v-else>
                            <button @click="itemId = entry.id" class="focus:outline-none all__link trans trans-slower">
                                <span class="link-text uppercase text-xs font-bold">
                                    Read More 
                                    <span class="svg-arrow-right">
                                        <!-- Arrow SVG -->
                                        <slot name="arrowIcon"></slot>
                                    </span>
                                </span>
                            </button>
                        </template>
                    </footer>

                </div>

                <!-- Back of card -->
                <div v-if="entry.gated" class="back bg-white h-full w-full"
                    :class="{ 
                        'absolute top-0 left-0': preserve3d,
                        'hidden': !preserve3d
                    }">
                    <div class="relative h-full w-full p-4 flex items-center pt-12 flex-col">
                        <button class="absolute top-0 left-0" @click="itemId = 0">
                            <slot name="backIcon"></slot>
                        </button>
                        <gated-form :redirectUrl="entry.url"></gated-form>
                    </div>
                </div>

            </article>
            <!-- END Article -->

        </div>
        
        <!-- END Column -->
    </transition-group>

</template>

<script>
import GatedForm from './GatedForm'
import truncate from 'vue-truncate-collapsed';

export default {
    name: 'ResourceCards',
    props: ['resources', 'hasError', 'omitToSecond'],
    data() {
        return {
            itemId: 0,
            preserve3d: Modernizr.preserve3d
        }
    },
    components: {
        GatedForm,
        truncate
    },
    methods: {
        // Emits to root and clears all active filters
        clearFilters() {
            this.$root.$emit('removeCriteria');
        },
        onCopy: function (e) {
            this.$noty.success("Link successfully copied");
        },
        onError: function (e) {
            this.$noty.error("Unable to copy link");
        }
    }
}
</script>

<style scoped lang="scss">
    @import 'node_modules/vuejs-noty/dist/vuejs-noty';
</style>

<style lang="scss">

    .resource__card {
        border-top: 3.5px solid #DD1F26;
    }

    .preserve3d {
        .resource__card {
            transition: 0.6s;
            transform-style: preserve-3d;
            &.flip {
                transform: rotateY(180deg);
            }
        }
    }

    .no-preserve3d {
        .resource__card {
            &.flip {
                .front {
                    display: none;
                }
                .back {
                    display: block;
                }
            }
        }
    }   

    .all__link {
        &:hover {
            cursor: pointer;
        }
    }

    .resource__list {
        @apply relative flex flex-wrap -mx-3;
        backface-visibility: hidden;
    }

    .entries {
        @apply mb-8 px-3;
        position: relative;
        backface-visibility: hidden;
        z-index: 1;
        .preserve3d & {
            perspective: 1000px;
            transform-style: preserve-3d;
            transform-origin: 10% 50%;
            // Moving
            &-move { transition: all 600ms ease-in-out 50ms }

            // Appearing
            &-enter-active { transition: all 300ms ease-out }

            // Dissapearing
            &-leave-active {
                transition: all 200ms ease-in;
                position: absolute;
                z-index: 0;
            }

            // Appear at / dissapear to
            &-enter,
            &-leave-to { opacity: 0 }
            &-enter { transform: scale(0.9) }
        }
    }

    .front, .back {
        backface-visibility: hidden;
        .preserve3d & {
            transition: 0.6s;
            transform-style: preserve-3d;
        }
    }

    /* front pane, placed above back */
    .front {
        z-index: 2;
        /* for firefox 31 */
        .preserve3d & {
            transform: rotateY(0deg);
        }
    }

    /* back, initially hidden pane */
    .back {
        .preserve3d & {
            transform: rotateY(180deg);
        }
    }
</style>
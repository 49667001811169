<template>
    <!-- Column -->
    <transition-group v-if="resources" name="entries" tag="div" class="resource__list flex flex-wrap -mx-1 lg:-mx-4">
        
        <span class="w-full ml-3 mb-5" key="featuredLabel">
            <slot name="featuredLabel"></slot>
        </span>

        <div class="entries px-1 px-3 w-full sm:w-1/2" 
            v-for="entry in resources"
            :key="entry.id">

            <!-- Article -->
            <article class="resource__card relative shadow-lg h-full"
                :class="{ 'flip': entry.id == itemId }">

                <!-- Front of card -->
                <div class="front bg-white h-full flex justify-center">

                    <!-- Lock Icon -->
                    <template v-if="entry.gated">
                        <slot name="lockIcon"></slot>
                    </template>

                    <div class="flex justify-center items-center">
                        <div class="px-2 hidden lg:block" v-if="entry.categoryIcon">
                            <div class="category__icon">
                                <img class="w-full h-32" :src="entry.categoryIcon" alt="">
                            </div>
                        </div>

                        <header class="flex flex-col justify-between mb-2 w-full lg:w-9/12 p-4">
                            <p class="text-xs font-bold mb-2">
                                <template v-if="entry.category">
                                    <span v-for="cat in entry.category" :key="cat" class="text-red-primary uppercase">
                                        {{ cat }}
                                    </span>
                                </template> 
                            </p>
                            <p class="text-xl font-bold leading-tight cursor-pointer text-black mb-2">
                                <template v-if="!entry.gated">
                                    <a class="no-underline text-black" :href="entry.url">
                                        {{ entry.title }}
                                    </a>
                                </template>
                                <template v-else>
                                    <span @click="itemId = entry.id">
                                        {{ entry.title }}
                                    </span>
                                </template>
                            </p>
                            
                            <div v-if="entry.overview" class="text-sm text-gray-muted mb-4" v-html="entry.overview"></div>

                            <footer class="flex items-center justify-between leading-none">
                                <template v-if="!entry.gated">
                                    <a :href="entry.url" target="_self" class="focus:outline-none text-black hover:text-black all__link outline-none trans trans-slower">
                                        <span class="link-text uppercase text-xs font-bold">
                                            Read More 
                                            <span class="svg-arrow-right">
                                                <!-- Arrow SVG -->
                                                <slot name="arrowIcon"></slot>
                                            </span>
                                        </span>
                                    </a>
                                </template>
                                <template v-else>
                                    <button @click="itemId = entry.id" class="focus:outline-none text-black hover:text-black all__link trans trans-slower">
                                        <span class="link-text uppercase text-xs font-bold">
                                            Read More 
                                            <span class="svg-arrow-right">
                                                <!-- Arrow SVG -->
                                                <slot name="arrowIcon"></slot>
                                            </span>
                                        </span>
                                    </button>
                                </template>
                            </footer>

                        </header>
                    </div>

                </div>

                <!-- Back of card -->
                <div v-if="entry.gated" class="back bg-white h-full w-full"
                    :class="{ 
                        'absolute top-0 left-0': preserve3d,
                        'hidden': !preserve3d
                     }">
                    <div class="relative h-full w-full p-4 flex items-center justify-center flex-col">
                        <button class="absolute top-0 left-0" @click="itemId = 0">
                            <slot name="backIcon"></slot>
                        </button>
                        <gated-form :redirectUrl="entry.url" rowLayout="true" class="w-full p-8"></gated-form>
                    </div>
                </div>

            </article>
            <!-- END Article -->

        </div>
        
        <!-- END Column -->
    </transition-group>
    
</template>


<script>
import axios from 'axios'
import qs from 'qs'
import GatedForm from './GatedForm'
export default {
    name: 'ResourceFeaturedCards',
     data() {
        return {
            resources: [],
            categories: [],
            hasError: false,
            isProcessing: false,
            itemId: 0,
            preserve3d: Modernizr.preserve3d
        }
    },
    components: {
        GatedForm
    },
    mounted() {
        this.getResources()
    },
    methods: {
        getResources(nextPage) {
            this.isProcessing = true
            axios({
                url: '/api/featured-resources.json',
                method: 'get',
            }).then((response) => {
                if (response.status == 200) {
                    if (response.data.data.length > 0) {
                        
                        // Reset error status
                        this.hasError = false;
                        this.resources = response.data.data;

                    } else {
                        this.hasError = true
                    }

                }
            }).catch((error) => {
                this.hasError = true
            })
        }
    }
}
</script>

<style scoped lang="scss">

    .resource__card {
        border-top: 3.5px solid #DD1F26;
    }

    .preserve3d {
        .resource__card {
            transition: 0.6s;
            transform-style: preserve-3d;
            &.flip {
                transform: rotateY(180deg);
            }
        }
    }

    .no-preserve3d {
        .resource__card {
            &.flip {
                .front {
                    display: none;
                }
                .back {
                    display: block;
                }
            }
        }
    }   

    .all__link {
        &:hover {
            cursor: pointer;
        }
    }

    .resource__list {
        @apply relative flex flex-wrap -mx-3;
        backface-visibility: hidden;
    }

    .entries {
        @apply mb-8 px-3;
        position: relative;
        backface-visibility: hidden;
        z-index: 1;
        .preserve3d & {
            perspective: 1000px;
            transform-style: preserve-3d;
            transform-origin: 10% 50%;
            // Moving
            &-move { transition: all 600ms ease-in-out 50ms }

            // Appearing
            &-enter-active { transition: all 300ms ease-out }

            // Dissapearing
            &-leave-active {
                transition: all 200ms ease-in;
                position: absolute;
                z-index: 0;
            }

            // Appear at / dissapear to
            &-enter,
            &-leave-to { opacity: 0 }
            &-enter { transform: scale(0.9) }
        }
    }

    .front, .back {
        backface-visibility: hidden;
        .preserve3d & {
            transition: 0.6s;
            transform-style: preserve-3d;
        }
    }

    /* front pane, placed above back */
    .front {
        z-index: 2;
        /* for firefox 31 */
        .preserve3d & {
            transform: rotateY(0deg);
        }
    }

    /* back, initially hidden pane */
    .back {
        .preserve3d & {
            transform: rotateY(180deg);
        }
    }
</style>

<template>
    <div class="toggle__container">
        <div class="w-full flex justify-between mb-10">
            <div class="md:w-full">
                <p class="heading-intro uppercase gray text-sm mb-2"><span>Currently Viewing:</span></p>
                <button class="btn outline small mr-4 focus:outline-none" 
                    :class="{ 'active invert': newsActive }"
                    @click="stateChange()">
                    News
                </button>
                <button class="btn outline small focus:outline-none"
                    :class="{ 'active invert': resourcesActive }"
                    @click="stateChange()">
                    Resources
                </button>
            </div>
            <div class="self-center hidden md:block mr-16">
                <a :href="activeStateSlug" target="_self" class="all__link trans trans-slower">
                    <span class="link-text">
                        View All {{ activeStateTitle }} 
                        <span class="svg-arrow-right">
                            <slot></slot>
                        </span>
                    </span>
                </a>
            </div>
        </div>
        
        <div class="toggle__news" key="news" v-if="newsActive">
            <news-cards :articles="articles" />
        </div>
        <div class="toggle__resources" key="resources" v-if="resourcesActive">
            <resource-cards :resources="resources">
                <!-- Passing icons to child component from parent -->
                <slot name="arrowIcon" slot="arrowIcon"></slot>
                <slot name="lockIcon" slot="lockIcon"></slot>
                <slot name="backIcon" slot="backIcon"></slot>
            </resource-cards>
        </div>

        <div class="self-center visible md:hidden mt-12">
            <a :href="activeStateSlug" target="_self" class="btn red mx-auto block text-center">View All {{ activeStateTitle }}</a>
        </div>

    </div>
</template>

<script>
import NewsCards from './NewsCards'
import ResourceCards from './ResourceCards'
export default {
    name: 'NewsResourceToggle',
    props: ['articles', 'resources'],
    components: { 
        NewsCards,
        ResourceCards
    },
    data() {
        return {
            newsActive: true,
            resourcesActive: false
        }
    },
    computed: {
        activeStateTitle () {
            let stateTitle = this.newsActive ? 'News' : 'Resources';
            return stateTitle;
        },
        activeStateSlug () {
            let stateSlug = this.newsActive ? 'news' : 'resources';
            return stateSlug;
        }
    },
    methods: {
        stateChange () {
            this.resourcesActive = !this.resourcesActive;
            this.newsActive = !this.newsActive
        }
    }
}
</script>

<style scoped lang="scss">
    @import 'node_modules/vue-transition-css/dist/css/vue-transition';
    button {
        @apply rounded-full text-gray-muted;
        &.active {
            @apply bg-red-primary;
        }
    }
    .all__link {
        margin-right: 70px;
    }
</style>